import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const HeroTitleStyle = styled.div`
  position: relative;
  min-height: 200px;
  max-height: 300px;
  z-index: -1;
  overflow: hidden;

  h1 {
    position: absolute;
    top: 7rem;
    z-index: 1;
    transition: top 0.5s;

    @media (min-width: 600px) {
      top: 8rem;
    }
  }
`;

export default props => (
  <StaticQuery
    query={graphql`
      {
        hero: file(relativePath: { eq: "paint-brush-bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <HeroTitleStyle>
        <Img fluid={data.hero.childImageSharp.fluid} />
        <div className="layoutInner">
          <h1>{props.title}</h1>
        </div>
      </HeroTitleStyle>
    )}
  />
);
