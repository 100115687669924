import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import logo from '../img/logo.svg';

const NavbarStyle = styled.div`
  background: rgba(45, 75, 150, 0.9);
  border-bottom: 1px solid #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .navbar-logo {
    padding: 1rem 1rem 0.5rem;

    img {
      max-width: 175px;
    }
  }

  a,
  a:hover {
    color: #fff;
  }

  .navbar {
    display: flex;
  }

  .navbar-menu {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 0.5rem 1rem 0;
    margin: 0.75rem;
    margin-left: auto;
  }
`;

const NavbarInner = styled.div`
  max-width: 1000px;
  margin: auto;
`;

const Navbar = class extends React.Component {
  render() {
    return (
      <NavbarStyle>
        <NavbarInner>
          <nav
            className="navbar"
            role="navigation"
            aria-label="main-navigation"
          >
            <div className="navbar-logo">
              <Link to="/" className="no-underline" title="Logo">
                <img src={logo} alt="D McAuley &amp; Sons" />
              </Link>
            </div>
            <div className="navbar-menu">
              <div className="navbar-start">
                <Link className="navbar-item no-underline" to="/contact">
                  Contact
                </Link>
              </div>
            </div>
          </nav>
        </NavbarInner>
      </NavbarStyle>
    );
  }
};

export default Navbar;
