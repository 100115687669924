import React, { Component } from 'react';
import styled from 'styled-components';

import facebookLogo from '../img/facebook-logo.png';
import instaLogo from '../img/insta-logo.png';

const FooterStyle = styled.div`
  background: #ccc;
  color: #020202;
  font-size: 0.85rem;
  margin: 0;

  footer {
    max-width: 1000px;
    position: relative;
    margin: auto;
    padding: 1rem;

    > div {
      text-align: left;
    }

    .footer__address {
      margin-bottom: 1rem;
    }

    .tel-cta {
      background: #dedede;
      display: block;
      margin-bottom: 1rem;
      padding: 1rem;
      font-size: 1rem;
    }
  }

  .footer__fb {
    img {
      max-width: 150px;
      margin-right: 1rem;
    }
  }

  .footer__phone {
    div {
      max-width: calc(100% - 70px);

      &:first-child {
        margin-right: 1rem;
      }
    }

    @media (min-width: 550px) {
      display: flex;
      div {
        max-width: 240px;
      }
    }

    @media (min-width: 800px) {
      position: absolute;
      right: 1rem;
      max-width: 500px;
    }
  }
`;

export default class Footer extends Component {
  render() {
    return (
      <FooterStyle>
        <footer>
          <div>
            <div className="footer__phone">
              <div>
                <span className="tel-cta" itemProp="telephone">
                  <span role="img" alt="Telephone">
                    <span role="img" aria-label="telephone">
                      📞
                    </span>
                  </span>
                  <a className="no-underline" href="tel:+4407810811028">
                    +44 (0) 781 081 1028
                  </a>{' '}
                  (Ciaran)
                </span>
              </div>
              <div>
                <span className="tel-cta" itemProp="telephone">
                  <span role="img" alt="Telephone">
                    <span role="img" aria-label="telephone">
                      📞
                    </span>
                  </span>
                  <a className="no-underline" href="tel:+440771 140 9149">
                    +44 (0) 771 140 9149
                  </a>{' '}
                  (Des)
                </span>
              </div>
            </div>
            <div className="footer__address">
              <div itemScope itemType="http://schema.org/LocalBusiness">
                &copy; 2019 <span itemProp="name">D. McAuley &amp; Sons</span>
                <br />
                <br />
                <div
                  itemType="http://schema.org/PostalAddress"
                  itemProp="address"
                >
                  <div itemProp="streetAddress">15a Loughinisland Rd</div>
                  <div>
                    <span itemProp="addressLocality">Annacloy</span>
                    <br /> <span itemProp="addressRegion">Downpatrick</span>
                    <br /> <span itemProp="postalCode">BT30 8PT</span>
                  </div>
                </div>
              </div>
              <br />
              <div itemScope itemType="http://schema.org/LocalBusiness">
                <div
                  itemType="http://schema.org/PostalAddress"
                  itemProp="address"
                >
                  <div itemProp="streetAddress">8 Ivanhoe Avenue</div>
                  <div>
                    <span itemProp="addressLocality">Carryduff</span>
                    <br /> <span itemProp="addressRegion">Belfast</span>
                    <br /> <span itemProp="postalCode">BT8 8BN</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__fb">
            <a
              className="no-underline"
              href="https://www.facebook.com/mcauleyandsons/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookLogo} alt="Facebook logo" />
            </a>
            <a
              className="no-underline"
              href="https://www.instagram.com/dmcauleyandsons/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instaLogo} alt="Instagram logo" />
            </a>
          </div>
        </footer>
      </FooterStyle>
    );
  }
}
