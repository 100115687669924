import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/global.scss';

const LayoutStyle = styled.div`
  height: 100vh;
  max-width: 100vw;
  margin: auto;
  padding: 0;
`;

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-16x16.png"
            sizes="16x16"
          />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content="https://www.dmcauleyandsons.com/" />
          <meta property="og:image" content="img/og-hero.jpg" />
          <meta charset="utf-8" />
          <meta name="image" content="img/og-hero.jpg" />>
          <meta
            itemprop="name"
            content="D. McAuley &amp; Sons - Painting and Decorating"
          />
          <meta
            itemprop="description"
            content="We are a family-owned painting and decorating business in our fourth generation of trading."
          />
          <meta itemprop="image" content="img/og-hero.jpg" />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content="D. McAuley & Sons - Painting and Decorating"
          />
          <meta
            name="twitter:description"
            content="We are a family-owned painting and decorating business in our fourth generation of trading."
          />
          <meta
            name="og:title"
            content="D. McAuley & Sons - Painting and Decorating"
          />
          <meta
            name="og:description"
            content="We are a family-owned painting and decorating business in our fourth generation of trading."
          />
          <meta name="og:url" content="https://www.dmcauleyandsons.com/" />
          <meta name="og:locale" content="en_GB" />
          <meta name="og:type" content="website" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2d4b96" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#2d4b96" />
        </Helmet>
        <Navbar />
        <LayoutStyle>
          {children}
          <Footer />
        </LayoutStyle>
      </>
    )}
  />
);

export default TemplateWrapper;
