import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';

import HeroTitle from '../../components/HeroTitle';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout>
        <HeroTitle title="Contact Us" />
        <div className="strip">
          <div className="row">
            <div className="formLayout">
              <div className="formLayout__form">
                <form
                  name="Contact"
                  method="post"
                  action="/contact/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <fieldset>
                    <legend>Your details</legend>
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field required">
                      <label className="label" htmlFor={'name'}>
                        Your name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'name'}
                          onChange={this.handleChange}
                          id={'name'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field required">
                      <label className="label" htmlFor={'email'}>
                        Your email address
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email'}
                          name={'email'}
                          onChange={this.handleChange}
                          id={'email'}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="field required">
                      <label className="label" htmlFor={'phone'}>
                        Phone number
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'number'}
                          name={'phone'}
                          onChange={this.handleChange}
                          id={'phone'}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="field required">
                      <label className="label" htmlFor={'address'}>
                        Address
                      </label>
                      <div className="control">
                        <textarea
                          className="input"
                          name={'address'}
                          onChange={this.handleChange}
                          id={'address'}
                          required={true}
                          rows="4"
                        />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor={'nature'}>
                        Nature of your inquiry{' '}
                      </label>
                      <div className="control">
                        <select
                          className="textarea"
                          name={'nature'}
                          onChange={this.handleChange}
                          id={'nature'}
                        >
                          <option value="">--Please choose an option--</option>
                          <option value="Internal painting">
                            Internal painting
                          </option>
                          <option value="External painting">
                            External painting
                          </option>
                          <option value="Hand-painted kitchen">
                            Hand-painted kitchen
                          </option>
                          <option value="Furniture painting">
                            Furniture painting
                          </option>
                          <option value="Insurance work">Insurance work</option>
                          <option value="Paper hanging">Paper hanging</option>
                          <option value="Wall covering">Wall covering</option>
                          <option value="Graining (Imitation wood effect)">
                            Graining (Imitation wood effect)
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="field required">
                      <label className="label" htmlFor={'message'}>
                        Your message
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'message'}
                          onChange={this.handleChange}
                          id={'message'}
                          required={true}
                          rows="6"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button type="submit">Send message</button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
